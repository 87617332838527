<template>
  <div>
    <v-dialog
      max-width="1000"
      v-model="dialog"
      v-if="dialog"
      scrollable
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-system-bar window>
          {{ item && item.type ? item.type.description : "—" }}
          <v-spacer /><v-btn icon @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-system-bar
        >
        <v-card-text class="ma-0 pa-0">
          <v-toolbar color="transparent" flat>
            <PersonItem :value="item.student" />
            <v-spacer />

            <ReportButton
              text
              outlined
              color="danger"
              :parameters="{
                id: item.id,
              }"
              resource="report/justificationform"
              >pdf</ReportButton
            >
          </v-toolbar>
          <v-divider />
          <v-alert v-if="showHalfDayWarning48" type="error" text prominent
            >Dieser Halbtag wurde weniger als 48 Stunden im Voraus eingereicht!
          </v-alert>
          <v-alert v-if="showHalfDayWarning5" type="error" text prominent
            >Es wurden bereits {{ halfDayCount.used }} Halbtage bezogen! Es sind
            nur {{ halfDayCount.max }} Halbtage pro Schuljahr erlaubt!
          </v-alert>
          <v-container>
            <v-row>
              <v-col cols="12" md="5">
                <v-list>
                  <v-list-item v-if="item.halfDay">
                    <v-list-item-content>
                      <v-list-item-subtitle>Datum</v-list-item-subtitle>
                      <v-list-item-title
                        ><DateValue :value="item.startDate" />,
                        {{
                          item.type.code == "halfDayMorning"
                            ? "vormittag"
                            : "nachmittag"
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!item.halfDay">
                    <v-list-item-content>
                      <v-list-item-subtitle>Beginn</v-list-item-subtitle>
                      <v-list-item-title
                        ><DateValue :value="item.startDate" />,
                        {{ item.startTime ? item.startTime : "morgens" }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="!item.halfDay">
                    <v-list-item-content>
                      <v-list-item-subtitle>Ende</v-list-item-subtitle>
                      <v-list-item-title
                        ><DateValue :value="item.endDate" />,
                        {{ item.endTime ? item.endTime : "abends" }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Status</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ item.status.description }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle>Kategorie </v-list-item-subtitle>
                      <v-list-item-title>
                        <LookupValue :value="item.category" hideCode />
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        v-if="!item.balanced && $_hasRole('teacher')"
                        small
                        color="primary"
                        icon
                        @click="catDialog = true"
                        ><v-icon>mdi-pen</v-icon></v-btn
                      >
                      <v-dialog v-model="catDialog" width="500">
                        <v-card>
                          <v-system-bar window> bearbeiten </v-system-bar>
                          <v-card-text>
                            <LookupValueInput
                              :disabled="!$_hasRole('teacher')"
                              v-model="newCategory"
                              :items="categories"
                              label="Kategorie"
                              ><template v-slot:append-outer>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on"
                                      >mdi-help-circle-outline</v-icon
                                    >
                                  </template>
                                  <span> Info für Fachlehrkräfte </span>
                                </v-tooltip></template
                              >
                            </LookupValueInput>
                          </v-card-text>
                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-btn text @click="catDialog = false">
                              abbrechen
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="saveCat">
                              speichern
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Entschuldigungstext Schüler*in
                      </v-list-item-subtitle>
                      {{ item.description ? item.description : "–" }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        v-if="
                          canEdit && !item.description && $_hasRole('student')
                        "
                        small
                        color="primary"
                        outlined
                        @click="edit"
                        ><v-icon left>mdi-pen</v-icon>bitte erfassen</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="$_hasRole('teacher')">
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >Kommentar Klassenlehrkraft
                      </v-list-item-subtitle>

                      {{ item.comments ? item.comments : "–" }}
                    </v-list-item-content>
                  </v-list-item>

                  <JustificationStatusMessage
                    v-for="(logEntry, index) in item.log"
                    :value="logEntry"
                    :key="`log-${index}`"
                  />
                </v-list>
              </v-col>
              <v-divider vertical />

              <v-col cols="12" md="7">
                <RegisterLessons
                  showAbsenceToggleButton
                  :person="item.student"
                  :startDate="item.startDate"
                  :startTime="item.startTime"
                  :endDate="item.endDate"
                  :endTime="item.endTime"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            text
            v-for="action in item.actions"
            :key="action.name"
            @click="doAction(action)"
            :color="justificationStatusColor(action.status)"
            >{{ action.name }}</v-btn
          >
          <v-spacer />
          <v-btn v-if="canEdit" @click="edit" text color="primary"
            >Bearbeiten</v-btn
          >
          <v-btn text @click="dialog = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay z-index="999" v-model="loading">
      <v-progress-circular size="64" indeterminate> </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { justificationStatusColor } from "common/utils/icons.js";

import DateValue from "common/components/DateValue.vue";
import JustificationStatusMessage from "common/components/Absences/JustificationStatusMessage.vue";
import PersonItem from "common/components/PersonItem.vue";
import ReportButton from "@/components/ReportButton.vue";
import RegisterLessons from "common/components/Register/RegisterLessons.vue";
import LookupValue from "common/components/LookupValue.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";

export default defineComponent({
  components: {
    DateValue,
    JustificationStatusMessage,
    PersonItem,
    ReportButton,
    RegisterLessons,
    LookupValue,
    LookupValueInput,
  },
  props: ["justificationId"],
  data() {
    return {
      abortController: null,
      dialog: true,
      catDialog: false,
      categories: [],
      item: { status: {} },
      halfDayCount: {
        max: 5,
        used: 0,
      },
      lessonMap: [],
      loading: true,
      newCategory: null,
      updated: false,
    };
  },
  computed: {
    canEdit() {
      return (
        this.item.status.code == "approved" ||
        this.item.status.code == "created" ||
        this.item.status.code == "rejected" ||
        this.item.status.code == "submitted"
      );
    },
    showHalfDayWarning48() {
      if (!this.item || !this.item.halfDay) {
        return false;
      }

      const creationTime = new Date(this.item.creationTime);
      const start = new Date(this.item.startDate + "T" + this.item.startTime);

      const delta =
        (start.getTime() - creationTime.getTime()) / (1000 * 60 * 60);
      return delta < 48;
    },
    showHalfDayWarning5() {
      if (!this.item || !this.item.halfDay) {
        return false;
      }
      return this.halfDayCount.max - this.halfDayCount.used <= 0;
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        if (this.updated) {
          this.$emit("updated");
        }
        if (this.$_hasRole("student")) {
          this.$router.push({
            name: "StudentAbsences",
          });
        } else if (this.$route.name == "JustificationPendingDetail") {
          this.$router.push({
            name: "AbsencesPending",
          });
        } else {
          this.$router.push({
            name: "AbsencesOverviewStudent",
            params: { id: this.item.student.id },
          });
        }
      }
    },
    justificationId() {
      this.fetchData();
    },
  },
  methods: {
    justificationStatusColor,
    edit() {
      if (this.item.balanced) {
        return false;
      }
      if (this.$_hasRole("student")) {
        this.$router.push({
          name: "JustificationStudentEdit",
          params: { justificationId: this.item.id },
        });
      } else if (this.$route.name == "JustificationPendingDetail") {
        this.$router.push({
          name: "JustificationPendingEdit",
          params: { justificationId: this.item.id },
        });
      } else {
        this.$router.push({
          name: "JustificationOverviewEdit",
          params: { id: this.item.student.id, justificationId: this.item.id },
        });
      }
    },

    async doPatch(status, message) {
      await this.apiPatch({
        resource: "absence/justification",
        id: this.item.id,
        key: "status",
        value: {
          id: status.id,
          message: message,
        },
      });
      this.updated = true;
    },

    async doAction(action) {
      const message = await this.$root.prompt({
        message: `Soll die Aktion «${action.name}» ausgeführt werden?`,
        label: "Nachricht",
        color: justificationStatusColor(action.status),
        optional: true,
      });
      if (message === false) {
        return;
      } else {
        await this.doPatch(action.status, message);
      }
      this.fetchData();
    },

    async saveCat() {
      await this.apiPatch({
        resource: "absence/justification",
        id: this.item.id,
        key: "category",
        value: this.newCategory.id,
      });
      this.catDialog = false;
      this.fetchData();
    },

    async fetchData() {
      if (!this.justificationId) return;

      this.loading = true;
      this.categories = await this.apiList({
        resource: "absence/justificationcategory",
      });

      this.item = await this.apiGet({
        resource: "absence/justification",
        id: this.justificationId,
        onError: () => {
          this.$router.back();
          return;
        },
      });

      this.newCategory = this.item.category;

      this.halfDayCount = await this.apiGet({
        resource: "absence/halfday",
        id: this.item.student.id,
      });

      this.loading = false;

      if (
        this.$_hasRole("student") &&
        !this.item.halfDay &&
        !this.item.description
      ) {
        if (
          await this.$root.confirm({
            message: `Es fehlt ein Entschuldigungstext. Soll dieser eingegeben werden?`,
            icon: "mdi-text",
          })
        ) {
          this.edit();
          return;
        }
      }
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
